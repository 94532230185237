<template>
  <v-app>
    <Header />
    <v-main>
      <TabelaFatura />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import TabelaFatura from "@/components/TabelaFatura.vue";

import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    TabelaFatura,
    Footer,
  },
};
</script>
