<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title class="text-h4" style="display: block">
          Cadastro de Cliente
        </v-card-title>
        <v-card-text>
          <!-- Campo texto nome -->
          <v-text-field label="Nome do responsável" v-model="name">
          </v-text-field>

          <!-- Campo texto email -->
          <v-text-field label="Email" v-model="email"> </v-text-field>

          <!-- Campo texto senha -->
          <v-text-field
            label="Senha"
            v-model="password"
            :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
          >
          </v-text-field>

          <span class="error--text font-weight-bold">{{ messange }}</span>

          <v-row>
            <v-col>
              <v-btn
                block
                color="#C3F207"
                class="text-none"
                @click="show = false"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                color="#C3F207"
                class="text-none"
                @click="submitSignUp"
              >
                Criar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Cliente from "@/repositories/cliente.js";
export default {
  data() {
    return {
      email: "",
      password: "",
      name: "",
      messange: "",
      value: String,
      loading: false,
      remember: false,
      userID: null,
      snackbar: false,
      text: "Cliente criado com sucesso",
      timeout: 2000,
    };
  },
  props: {
    dialogCliente: Boolean,
  },
  computed: {
    ...mapState(["notAuthenticated", "notCreated"]),
    show: {
      get() {
        return this.dialogCliente;
      },
      set(value) {
        this.$emit("close", value);
      },
    },
  },
  methods: {
    ...mapActions({
      signUpVue: "auth/signUp",
      confirmVue: "auth/confirmSignUp",
    }),
    async submitSignUp() {
      try {
        await this.signUpVue({
          username: this.email,
          password: this.password,
          email: this.email,
          name: this.name,
        }).then((data) => {
          this.userID = data;
        });

        this.confirmPassword = true;

        const newUser = {
          userID: this.userID,
          name: this.name,
        };

        Cliente.criarCliente(newUser).then((response) => {
          response
            .json()
            .then(() => {
              this.show = false;
              this.snackbar = true
            })
            .catch((error) => console.log("error", error));
        });
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>

<style>
</style>