<template>
  <div>
    <router-link to="/home">
      <v-img
        max-width="150"
        class="ma-auto"
        src="https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?w=800&ssl=1 800w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=300%2C300&ssl=1 300w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=150%2C150&ssl=1 150w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=768%2C768&ssl=1 768w"
      ></v-img>
    </router-link>

    <v-card class="ma-auto mt-10" max-width="570">
      <v-toolbar dark color="grey lighten-3" dense flat>
        <v-toolbar-title class="text-body-1 font-weight-bold grey--text">
          Esqueci minha senha
        </v-toolbar-title>
      </v-toolbar>
      <v-window v-model="step" touchless>
        <v-window-item :value="1">
          <v-card-subtitle>
            Para redefinir a sua senha, informe seu email abaixo e clique em
            Continuar.
          </v-card-subtitle>
          <v-card-text>
            <!-- Campo texto email -->
            <v-text-field
              label="Email"
              v-model="email"
              style="width: 480px"
              class="ma-auto"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              width="200"
              color="#C3F207"
              class="text-none ma-auto"
              :loading="loading"
              @click="forgotPassword"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-window-item>
        <v-window-item :value="2">
          <v-card-subtitle>
            Use o código de confirmação que foi enviado para o seu email.
          </v-card-subtitle>
          <v-card-text>
            <!-- Campo texto email -->
            <v-text-field
              label="Código de confirmação"
              v-model="code"
              style="width: 480px"
              class="ma-auto"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              width="200"
              color="#C3F207"
              class="text-none ma-auto"
              :loading="loading"
              @click="step++"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-window-item>
        <v-window-item :value="3">
          <v-card-subtitle> Insira sua nova senha. </v-card-subtitle>
          <v-card-text>
            <!-- Campo texto senha -->
            <v-text-field
              label="Nova Senha"
              v-model="newPassword"
              :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              width="200"
              color="#C3F207"
              class="text-none ma-auto"
              :loading="loading"
              @click="confirForgotPass"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-window-item>
        <v-window-item :value="4">
          <v-card-text>
            <span>Senha alterada com sucesso!</span>
          </v-card-text>
          <v-card-actions>
            <v-btn
              width="200"
              color="#C3F207"
              class="text-none ma-auto"
              :loading="loading"
              to="/login"
            >
              Voltar para tela de login
            </v-btn>
          </v-card-actions>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      email: "",
      code: "",
      newPassword: "",
      loading: false,
      value: String,
      step: 1,
    };
  },
  methods: {
    ...mapActions({
      forgotPass: "auth/forgotPass",
      confirmforgotpass: "auth/ConfirmforgotPass",
    }),
    async forgotPassword() {
      this.loading = true;
      try {
        await this.forgotPass({
          email: this.email,
        });
        this.loading = false;
        this.step++;
      } catch (error) {
        this.error = error;
        this.messange = "Email invalidos";
        this.loading = false;
      }
    },
    async confirForgotPass() {
      this.loading = true;
      try {
        await this.confirmforgotpass({
          email: this.email,
          code: this.code,
          new_password: this.newPassword,
        });
        setTimeout(function () {
          this.loading = false;
          this.step++;
        }, 500);
      } catch (error) {
        this.error = error;
        this.messange = "Email invalido";
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>