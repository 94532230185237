export default {
    async criarCliente(Event) {
        const api = 'https://jd0kah7l0m.execute-api.sa-east-1.amazonaws.com/dev/cliente'
        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",

            },
            body: JSON.stringify(Event)

        }
        return fetch(api, params)
    },

    async recuperarClientes() {
        const api = 'https://jd0kah7l0m.execute-api.sa-east-1.amazonaws.com/dev/clientes'
        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                
            }
        }
        return fetch(api, params)
    },
}