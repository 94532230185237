<template>
  <div>
    <div class="textBox ma-auto">
      <span>
        "Ainda há muito a ser inventado. Muita coisa nova ainda var acontecer.
        Ninguém ainda faz ideia do impacto que a internet produzirá e de que em
        muitos aspectos estamos apenas no primeiro dia."
      </span>
    </div>
    <v-img
      class="ma-auto hidden-sm-and-down"
      style="position:absolute;bottom:2%"
      src="@/assets/img/people.png"
    ></v-img>
    <div class="ma-auto boxIMG hidden-md-and-up">
      <img src="@/assets/img/peopleMobile.png" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.textBox {
  margin: 0 auto;
  max-width: 500px;
  margin-top: 60px !important;
  font-size: 19px;
}
@media only screen and (max-width: 960px) {
  .textBox {
    font-size: 10px;
    margin: 10px 20px !important;
  }
  .boxIMG {
    width: 100%;
    overflow: hidden;
  }
  .boxIMG img {
    width: 130%;
    margin-top: -15% !important;
    margin-left: -11% !important;
    margin-bottom: -8% !important;
  }
}
</style>