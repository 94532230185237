<template>
  <v-app>
    <v-main>
      <ForgotPassword />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
// @ is an alias to /src
import ForgotPassword from "@/components/Login/ForgotPassword.vue";
export default {
  name: "Home",
  components: {
    ForgotPassword,
  },
};
</script>
<style scoped>
.v-main {
  background: url("../assets/img/fundo.jpg") no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
</style>
