/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bd08c972-6e7e-4ba0-81fe-e4661a49b021",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_KjwiscNPP",
    "aws_user_pools_web_client_id": "300m47puv5t8eubuu68lqqfcs8",
    "oauth": {}
};


export default awsmobile;
