<template>
  <!-- style="max-width: 1040px" class="my-5" -->
  <v-container style="max-width: 1200px">
    <v-row>
      <v-col cols="12" md="3">
        <FaturaTotal :faturas="faturas" class="marginBox" />
      </v-col>
      <v-col cols="12" md="9">
        <v-row no-gutters>
          <v-col cols="12" sm="2">
            <v-select
              v-model="search"
              :items="['Pagas', 'Em aberto', 'Vencidas', 'Canceladas']"
              label="Todas"
              solo
              clearable
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            v-if="UserGroup ? UserGroup[0] === 'Admin' : ''"
            cols="12"
            sm="3"
            :class="
              $vuetify.breakpoint.smAndDown ? 'text-center my-2' : 'text-right'
            "
          >
            <AddCliente
              :key="componentKey"
              :dialogCliente="dialogCliente"
              @close="dialogCliente = false"
            />
            <v-btn
              class="text-none black--text"
              color="#C3F207"
              @click="showCliente"
              >Adicionar um Cliente
            </v-btn>
          </v-col>
          <v-col
            v-if="UserGroup ? UserGroup[0] === 'Admin' : ''"
            cols="12"
            sm="3"
            :class="
              $vuetify.breakpoint.smAndDown ? 'text-center my-2' : 'text-right'
            "
          >
            <AddFatura
              :key="componentKey"
              :dialogFatura="dialogFatura"
              @close="dialogFatura = false"
            />
            <v-btn
              class="text-none black--text"
              color="#C3F207"
              @click="showFatura"
              >Adicionar uma Fatura</v-btn
            >
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="faturas"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="faturaNumero"
          show-expand
          :search="search"
          class="elevation-1"
        >
          <!-- Template da linha expandida -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              Código de barras {{ item.faturaNumero }}
              <v-btn
                text
                class="text-none"
                @click="copiarCodigo(item.faturaNumero)"
              >
                <v-icon size="20">mdi-checkbox-multiple-blank-outline</v-icon>
                copiar
              </v-btn>
            </td>
          </template>

          <template v-slot:[`item.statusFatura`]="{ item }">
            <v-avatar
              size="10"
              :color="setCor(item.statusFatura)"
              class="mr-2"
            ></v-avatar>
            <span>{{ item.statusFatura }}</span>
          </template>
          <!-- Template ações -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-row no-gutters>
              <EditarStatus
                v-if="UserGroup ? UserGroup[0] === 'Admin' : ''"
                :item="item"
              />
              <a
                :href="item.fatura"
                @click.prevent="faturafile(item.fatura)"
                style="text-decoration: none"
              >
                <v-icon> mdi-download-circle </v-icon>
              </a>
              <!-- <v-icon>mdi-barcode</v-icon> -->
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- <a :href="faturafiles" @click.prevent="faturafile('item')">TesteDownload</a> -->
  </v-container>
</template>

<script>
import AddFatura from "@/components/AddFatura.vue";
import AddCliente from "@/components/AddCliente.vue";
import EditarStatus from "@/components/EditarStatus.vue";
import FaturaTotal from "@/components/FaturaTotal.vue";
import Fatura from "@/repositories/fatura.js";
import { mapGetters, mapActions } from "vuex";
// import {fs} from 'fs';
// import { Auth } from "aws-amplify";

export default {
  components: {
    AddFatura,
    AddCliente,
    EditarStatus,
    FaturaTotal,
  },
  data() {
    return {
      search: "",
      userID: "",
      dialogCliente: false,
      dialogFatura: false,
      expanded: [],
      componentKey: 0,
      headers: [
        { text: "Produto", value: "produto" },
        { text: "Fatura Nº", value: "faturaNumero" },
        { text: "Mês Ref", value: "mesReferencia" },
        { text: "Data de Envio", value: "envioData" },
        { text: "Vencimento", value: "vencimento" },
        { text: "Total", value: "total" },
        { text: "Status", value: "statusFatura" },
        { text: "Ações", value: "actions" },
        { value: "data-table-expand" },
      ],
      faturas: [
        // {
        //   produto: "Serviço variado",
        //   faturaNumero: "123",
        //   mesReferencia: "01/01/1990",
        //   envioData: "01/01/1990",
        //   vencimento: "02/01/1990",
        //   total: 71,
        //   statusFatura: "Em aberto",
        // },
      ],
    };
  },
  watch: {
    allFaturas: function () {
      this.faturas = this.filterFaturas();
    },
  },
  computed: {
    ...mapGetters(["allFaturas"]),
    UserGroup: {
      get() {
        return this.$store.state.auth.UserGroup;
      },
      set(value) {
        this.$store.commit("auth/setUserGroup", value);
      },
    },
  },
  methods: {
    ...mapActions(["loadFaturas"]),
    setCor(status) {
      if (status === "Em aberto") {
        return "blue";
      } else if (status === "Pagas") {
        return "green";
      } else if (status === "Vencidas") {
        return "red";
      } else if (status === "Canceladas") {
        return "grey";
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    showCliente() {
      this.forceRerender();
      this.dialogCliente = true;
    },
    showFatura() {
      this.forceRerender();
      this.dialogFatura = true;
    },
    // async getSession() {
    //   const isSession = await Auth.currentSession();
    //   if (isSession) {
    //     this.UserGroup = isSession.accessToken.payload["cognito:groups"];
    //     this.userID = isSession.accessToken.payload.username;
    //   }
    // },
    faturafile(name) {
      const faturaName = {
        fatura: name,
      };
      Fatura.recuparFatura(faturaName).then((response) => {
        response
          .json()
          .then((data) => {
            const arr = new Uint8Array(data.Body.data);
            const file = new Blob([arr], { type: "application/pdf" });

            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = name;
            link.click();
            setTimeout(function () {
              window.URL.revokeObjectURL(link.href);
            }, 100);
            // URL.revokeObjectURL(link.href);
            // window.open(fileURL, "_blank");
          })
          .catch((error) => console.log("error", error));
      });
    },
    filterFaturas() {
      if (this.UserGroup ? this.UserGroup[0] === "Admin" : "") {
        return this.allFaturas;
      } else {
        var faturas = [];
        this.allFaturas.find((item) => {
          if (item.clienteid === this.$store.state.auth.user.username) {
            faturas.push(item);
          }
        });
        return faturas;
      }
    },
    copiarCodigo(codigo) {
      this.$copyText(codigo).then(function () {
        alert("Copiado");
      });
    },
  },
  mounted() {
    this.loadFaturas();
    // this.faturafile();
    // this.getSession();
  },
};
</script>

<style scoped>
.mobile {
  color: #333;
}

@media screen and (max-width: 960px) {
  .marginBox {
    margin-top: 0 !important;
  }
}
.flex-content {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.flex-item {
  padding: 5px;
  width: 50%;
  height: 40px;
  font-weight: bold;
}
.marginBox {
  margin-top: 28%;
}
</style>
