var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"1200px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('FaturaTotal',{staticClass:"marginBox",attrs:{"faturas":_vm.faturas}})],1),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"items":['Pagas', 'Em aberto', 'Vencidas', 'Canceladas'],"label":"Todas","solo":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),(_vm.UserGroup ? _vm.UserGroup[0] === 'Admin' : '')?_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'text-center my-2' : 'text-right',attrs:{"cols":"12","sm":"3"}},[_c('AddCliente',{key:_vm.componentKey,attrs:{"dialogCliente":_vm.dialogCliente},on:{"close":function($event){_vm.dialogCliente = false}}}),_c('v-btn',{staticClass:"text-none black--text",attrs:{"color":"#C3F207"},on:{"click":_vm.showCliente}},[_vm._v("Adicionar um Cliente ")])],1):_vm._e(),(_vm.UserGroup ? _vm.UserGroup[0] === 'Admin' : '')?_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? 'text-center my-2' : 'text-right',attrs:{"cols":"12","sm":"3"}},[_c('AddFatura',{key:_vm.componentKey,attrs:{"dialogFatura":_vm.dialogFatura},on:{"close":function($event){_vm.dialogFatura = false}}}),_c('v-btn',{staticClass:"text-none black--text",attrs:{"color":"#C3F207"},on:{"click":_vm.showFatura}},[_vm._v("Adicionar uma Fatura")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.faturas,"single-expand":true,"expanded":_vm.expanded,"item-key":"faturaNumero","show-expand":"","search":_vm.search},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Código de barras "+_vm._s(item.faturaNumero)+" "),_c('v-btn',{staticClass:"text-none",attrs:{"text":""},on:{"click":function($event){return _vm.copiarCodigo(item.faturaNumero)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-checkbox-multiple-blank-outline")]),_vm._v(" copiar ")],1)],1)]}},{key:"item.statusFatura",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"10","color":_vm.setCor(item.statusFatura)}}),_c('span',[_vm._v(_vm._s(item.statusFatura))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[(_vm.UserGroup ? _vm.UserGroup[0] === 'Admin' : '')?_c('EditarStatus',{attrs:{"item":item}}):_vm._e(),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":item.fatura},on:{"click":function($event){$event.preventDefault();return _vm.faturafile(item.fatura)}}},[_c('v-icon',[_vm._v(" mdi-download-circle ")])],1)],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }