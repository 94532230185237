<template>
  <v-app>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" class="fullScreenLeft" style="">
            <LoginContent />
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="fullScreenRigth"
            style="background-color: white"
          >
            <LoginCard />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoginCard from "@/components/Login/LoginCard.vue";
import LoginContent from "@/components/Login/LoginContent.vue";
export default {
  name: "Login",
  components: {
    LoginCard,
    LoginContent,
  },
};
</script>

<style scoped>
  .fullScreenLeft {
    background-color: #C3F207;
  }
@media only screen and (max-width: 960px) {
  .fullScreenLeft {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    border-radius: 0 0 20px 20px;
    background: radial-gradient(circle at bottom center, transparent 19%, #C3F207 11px, #C3F207 100%, transparent 0)
  }
}
@media only screen and (min-width: 960px) {
  .fullScreenLeft {
    background-color: #C3F207;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: url("../assets/img/fundo.jpg") no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }
  .fullScreenRigth {
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
</style>