import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import { Auth } from 'aws-amplify'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = await Auth.currentUserInfo();
  if (requiresAuth && !isAuthenticated) {
    next("/login")
  } else {
    if(isAuthenticated){
      if (isAuthenticated.attributes["custom:challengeName"] === "NEW_PASSWORD_REQUIRED") {
        next("/login");
      } else {
        next()
      }
    }else{
      next()
    }
    // next()
  }
  
  if(isAuthenticated){
    if (isAuthenticated.attributes["custom:challengeName"] === "COMPLETE") {
      next("/home");
    } else {
      next()
    }
  }else{
    next()
  }
})

export default router
