<template>
  <div>
    <v-img
      max-width="150"
      class="logoBox centerBox"
      src="https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?w=800&ssl=1 800w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=300%2C300&ssl=1 300w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=150%2C150&ssl=1 150w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=768%2C768&ssl=1 768w"
    ></v-img>

    <v-card class="centerBox" elevation="0" max-width="400">
      <v-card-title class="text-h4" style="display: block">
        Financeiro
      </v-card-title>
      <v-card-subtitle class="hidden-md-and-up">
        ÁREA DE MEMBROS
      </v-card-subtitle>
      <v-card-text class="hidden-sm-and-down">
        <span> Acesse todas as suas faturas aqui </span>
        <!-- Campo texto email -->
        <v-text-field label="Email" v-model="email"> </v-text-field>

        <!-- Campo texto senha -->
        <v-text-field
          label="Senha"
          v-model="password"
          :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="() => (value = !value)"
          :type="value ? 'password' : 'text'"
        >
        </v-text-field>

        <span class="error--text font-weight-bold">{{ messange }}</span>

        <v-row>
          <v-col>
            <!-- Campo Checkbox Lembrar -->
            <!-- <v-checkbox
              color="#4785c7"
              label="Lembrar"
              v-model="remember"
              value="value"
              class="font-weight-bold"
            ></v-checkbox> -->
          </v-col>
          <v-col>
            <div
              class="font-weight-bold"
              style="margin-top: 16px; padding-top: 4px"
            >
              <router-link to="/forgotpassword">
                <span>Esqueceu a senha?</span>
              </router-link>
            </div>
          </v-col>
        </v-row>

        <v-btn
          block
          color="#C3F207"
          class="text-none my-5"
          :loading="loading"
          @click="login"
        >
          Entrar
        </v-btn>
      </v-card-text>

      <v-card-text class="hidden-md-and-up">
        <span> Acesse todas as suas faturas aqui </span>
        <v-btn
          block
          color="#C3F207"
          class="text-none my-5 rounded-pill"
          :loading="loading"
          @click="mobiledialog = !mobiledialog"
        >
          Entrar
        </v-btn>
      </v-card-text>
    </v-card>

    <div style="position: absolute; bottom: 1%; left: 50px; right: 50px">
      <span>Powered by Brutalit</span>
    </div>

    <!-- Login Mobile -->
    <v-dialog fullscreen v-model="mobiledialog">
      <v-card>
        <v-img
          max-width="120"
          class="ma-auto "
          src="https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?w=800&ssl=1 800w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=300%2C300&ssl=1 300w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=150%2C150&ssl=1 150w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=768%2C768&ssl=1 768w"
        ></v-img>
        <v-card-title class="pt-10"><v-icon class="mr-2" @click="mobiledialog = !mobiledialog">mdi-arrow-left</v-icon> Login </v-card-title>
        <v-card-text>
          <!-- Campo texto email -->
          <v-text-field label="Email" v-model="email"> </v-text-field>

          <!-- Campo texto senha -->
          <v-text-field
            label="Senha"
            v-model="password"
            :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
          >
          </v-text-field>

          <span class="error--text font-weight-bold">{{ messange }}</span>

          <v-row>
            <v-col>
              <!-- Campo Checkbox Lembrar -->
              <!-- <v-checkbox
              color="#4785c7"
              label="Lembrar"
              v-model="remember"
              value="value"
              class="font-weight-bold"
            ></v-checkbox> -->
            </v-col>
            <v-col>
              <div
                class="font-weight-bold"
                style="margin-top: 16px; padding-top: 4px"
              >
                <router-link to="/forgotpassword">
                  <span>Esqueceu a senha?</span>
                </router-link>
              </div>
            </v-col>
          </v-row>

          <v-btn
            block
            color="#C3F207"
            style="z-index: 1"
            class="text-none my-5"
            :loading="loading"
            @click="login"
          >
            Entrar
          </v-btn>
        </v-card-text>
        <!-- <v-img
          max-width="70%"
          class="ma-auto"
          src="@/assets/img/person_point.png"
        ></v-img> -->
      </v-card>
    </v-dialog>

    <!-- Modal verificação de primeiro login -->
    <v-dialog
      v-model="dialog"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title style="display: block; font-size: 19px">
          Foi detectado que este é o primeiro login<br />
          Por favor cadastre uma nova senha abaixo
        </v-card-title>
        <v-card-text>
          <!-- Campo texto senha -->
          <v-text-field
            label="Senha"
            v-model="newpassword"
            :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
          >
          </v-text-field>

          <v-btn
            width="200"
            color="#C3F207"
            class="text-none ma-auto"
            :loading="loadingPass"
            @click="newPassword"
          >
            Cadastrar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      dialog: false,
      mobiledialog: false,
      acceptTerm: false,
      email: "",
      password: "",
      newpassword: "",
      value: String,
      loading: false,
      loadingPass: false,
      messange: "",
      remember: false,
      userID: null,
    };
  },
  methods: {
    ...mapActions({
      loginVue: "auth/login",
      newPasswordVue: "auth/completeNewPassword",
    }),
    async login() {
      this.loading = true;
      try {
        await this.loginVue({
          username: this.email,
          password: this.password,
        }).then((data) => {
          if (data === "NEW_PASSWORD_REQUIRED") {
            this.dialog = true;
          } else {
            this.$router.push("/home");
          }
        });
        this.loading = false;
      } catch (error) {
        this.error = error;
        this.messange = "Email ou Senha invalidos";
        this.loading = false;
      }
    },
    async newPassword() {
      this.loadingPass = true;
      try {
        await this.newPasswordVue({
          oldPassword: this.password,
          newPassword: this.newpassword,
        });
        this.password = "";
        this.dialog = false;
        this.loadingPass = false;
        alert("senha alterada");
      } catch (error) {
        this.error = error;
        this.loadingPass = false;
      }
    },
  },
};
</script>

<style scoped>
.centerBox {
  margin: 0 auto;
}
.personBottom{
  position: absolute;
  bottom: 0;
}
@media only screen and (max-width: 960px) {
  .logoBox {
    max-width: 30% !important;
    margin: 0 auto;
    margin-top: -20% !important;
  }
}
</style>