export default {
    async criarFatura(Event) {
        const api = 'https://jd0kah7l0m.execute-api.sa-east-1.amazonaws.com/dev/fatura'
        const params = {
            method: 'POST',
            body: Event

        }
        return fetch(api, params)
    },

    async mudarStatus(Event) {
        const api = 'https://jd0kah7l0m.execute-api.sa-east-1.amazonaws.com/dev/faturastatus'
        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",

            },
            body: JSON.stringify(Event)

        }
        return fetch(api, params)
    },

    async recuparFatura(Event) {
        const api = 'https://jd0kah7l0m.execute-api.sa-east-1.amazonaws.com/dev/faturafile'
        const params = {
            method: 'POST',
            headers: {
                "Content-type": "application/json",

            },
            body: JSON.stringify(Event)

        }
        return fetch(api, params)
    },

    async recuperarFaturas() {
        const api = 'https://jd0kah7l0m.execute-api.sa-east-1.amazonaws.com/dev/faturas'
        const params = {
            method: 'GET',
            headers: {
                "Content-type": "application/json",
                "Accept": "application/json",
                
            }
        }
        return fetch(api, params)
    },
}