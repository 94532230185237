import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth/auth';
import Fatura from "@/repositories/fatura.js"
import Cliente from "@/repositories/cliente.js"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  },
  state: {
    faturas: [],
    clientes: [],
  },
  getters: {
    allFaturas: state => {
      return state.faturas
    },
    allClientes: state => {
      return state.clientes
    }
  },
  mutations: {
    SET_FATURA(state, data) {
      state.faturas = data
    },
    SET_CLIENTES(state, data) {
      state.clientes = data
    },
  },
  actions: {
    async loadFaturas({ commit }) {
      Fatura.recuperarFaturas().then((response) => {
        response
          .json()
          .then((data) => {
            commit('SET_FATURA', data)
          })
          .catch((error) => console.log("error", error));
      });

    },
    async loadClientes({ commit }) {
      Cliente.recuperarClientes().then((response) => {
        response
          .json()
          .then((data) => {
            commit('SET_CLIENTES', data)
          })
          .catch((error) => console.log("error", error));
      });

    }
  },
})
