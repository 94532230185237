<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title class="headline"> Adicionar Fatura </v-card-title>
        <v-card-text>
          <v-select
            v-model="cliente"
            :items="allClientes"
            item-text="name"
            label="Nome do Cliente"
            return-object
          ></v-select>
          <v-text-field
            v-model="produto"
            name="produto_servico"
            label="Produto/Serviço"
          ></v-text-field>
          <v-text-field
            v-model="faturaNumero"
            name="numero_fatura"
            type="number"
            label="Numero da Fatura"
          ></v-text-field>
          <!-- <v-text-field name="name" label="Status"></v-text-field> -->

          <v-dialog
            ref="dialog1"
            v-model="mesRef"
            :return-value.sync="dataMesRef"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataMesRef"
                label="Mês de Referencia"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataMesRefPick"
              locale="pt-br"
              :min="new Date().toISOString().substr(0, 10)"
              @input="
                mesRef = false;
              "
            >
                <!-- $refs.dialog1.save(dataMesRef); -->
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="dialog2"
            v-model="dataEnv"
            :return-value.sync="dataEnvio"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataEnvio"
                label="Data de Envio"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataEnvioPick"
              locale="pt-br"
              :min="new Date().toISOString().substr(0, 10)"
              @input="dataEnv = false"
            >
              <!-- $refs.dialog2.save(dataEnvio); -->
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="dialog3"
            v-model="dataVenc"
            :return-value.sync="dataVencimento"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dataVencimento"
                label="Data de Vencimento"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataVencimentoPick"
              locale="pt-br"
              :min="new Date().toISOString().substr(0, 10)"
              @input="dataVenc = false"
            >
              <!-- $refs.dialog3.save(dataVencimento); -->
            </v-date-picker>
          </v-dialog>

          <v-text-field
            v-model="total"
            name="total"
            type="number"
            label="Total"
          ></v-text-field>

          <v-file-input
            v-model="fatura"
            accept=".pdf"
            label="Fatura"
          ></v-file-input>
          <v-row>
            <v-col>
              <v-btn
                block
                color="#C3F207"
                class="text-none"
                @click="show = false"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col>
              <v-btn block color="#C3F207" class="text-none" @click="submit()">
                Criar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Fatura from "@/repositories/fatura.js";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      cliente: {},
      mesRef: false,
      dataEnv: false,
      dataVenc: false,
      produto: "",
      faturaNumero: "",
      dataMesRefPick: "",
      dataEnvioPick: "",
      dataVencimentoPick: "",
      date: "",
      total: "",
      fatura: [],
      codigoFatura: "",
      snackbar: false,
      text: "Fatura cadastrada com sucesso",
      timeout: 2000,
    };
  },
  // watch: {
  //   date(val) {
  //     this.dataEnvio = this.formatDate(val);
  //   },
  // },
  props: {
    dialogFatura: Boolean,
  },
  computed: {
    ...mapGetters(["allClientes"]),
    dataEnvio() {
      return this.formatDate(this.dataEnvioPick);
    },
    dataMesRef() {
      return this.formatDate(this.dataMesRefPick);
    },
    dataVencimento() {
      return this.formatDate(this.dataVencimentoPick);
    },
    show: {
      get() {
        return this.dialogFatura;
      },
      set(value) {
        this.$emit("close", value);
      },
    },
  },
  methods: {
    ...mapActions(["loadFaturas", "loadClientes"]),
    submit() {
      var formData = new FormData();
      formData.append("name", this.cliente.name);
      formData.append("userID", this.cliente.pk);
      formData.append("produto", this.produto);
      formData.append("faturaNumero", this.faturaNumero);
      formData.append("mesReferencia", this.dataMesRef);
      formData.append("envioData", this.dataEnvio);
      formData.append("vencimento", this.dataVencimento);
      formData.append("total", this.total);
      formData.append("fatura", this.fatura);
      formData.append("codigoFatura", this.codigoFatura);
      formData.append("statusFatura", "Em aberto");

      // for (var value of formData.values()) {
      //   console.log(value);
      // }
      Fatura.criarFatura(formData).then((response) => {
        response
          .json()
          .then(() => {
            this.show = false;
            this.snackbar = true;
            this.loadFaturas();
          })
          .catch((error) => console.log("error", error));
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
  },
  mounted() {
    this.loadClientes();
  },
};
</script>

<style>
</style>