<template>
  <div>
    <v-app-bar app color="#C3F207" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2 hidden-sm-and-up"
          contain
          src="https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?w=800&ssl=1 800w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=300%2C300&ssl=1 300w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=150%2C150&ssl=1 150w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=768%2C768&ssl=1 768w"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="70"
          src="https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?w=800&ssl=1 800w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=300%2C300&ssl=1 300w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=150%2C150&ssl=1 150w, https://i0.wp.com/brutalit.com.br/wp-content/uploads/2020/07/logo-3-Preta.png?resize=768%2C768&ssl=1 768w"
          width="70"
        />
      </div>

      <v-spacer></v-spacer>
      <span style="color:black">Bem-vindo {{ $store.state.auth.user? $store.state.auth.user.attributes.name : "" }}</span>
      
      <v-btn class="black--text text-none" text @click="logout">
        <span class="mr-2">Sair</span>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/user",
    }),
  },
  methods: {
    ...mapActions(["logOut", "allItenstotalPrice"]),
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
</style>