<template>
  <div>
    <v-icon @click="dialog = !dialog" color="blue"> mdi-pencil-circle </v-icon>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title style="display: block"> Alterar Status </v-card-title>
        <v-card-text>
          <!-- Campo texto nome -->
          <v-select
            :items="possiveiStatus"
            label="Status"
            v-model="status"
            dense
          ></v-select>

          <v-row>
            <v-col>
              <v-btn
                block
                color="#C3F207"
                class="text-none"
                @click="dialog = !dialog"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                color="#C3F207"
                class="text-none"
                @click="imprimirItem"
              >
                Alterar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="success">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Fatura from "@/repositories/fatura.js";
export default {
  data() {
    return {
      dialog: false,
      status: "",
      possiveiStatus: ["Em aberto", "Pagas", "Vencidas", "Canceladas"],
      snackbar: false,
      text: "Status alterado com sucesso",
      timeout: 2000,
    };
  },
  props: {
    item: Object,
  },
  methods: {
    ...mapActions(["loadFaturas"]),
    imprimirItem() {
      const newStatus = {
        faturaID: this.item.pk,
        status: this.status,
      };
      Fatura.mudarStatus(newStatus).then((response) => {
        response
          .json()
          .then(() => {
            this.dialog = false;
            this.snackbar = true;
            this.loadFaturas();
          })
          .catch((error) => console.log("error", error));
      });
    },
  },
  mounted() {
    this.status = this.item.statusFatura;
  },
};
</script>

<style>
</style>