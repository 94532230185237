<template>
  <v-card color="#C3F207">
    <v-card-text class="text-center black--text">
      <span>{{ qtdAberto }} fatura(s) em aberto </span>
      <p>Total:</p>
      <h1>R$ {{ totalFaturas }}</h1>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    faturas: Array,
  },
  computed: {
    qtdAberto: {
      get() {
        var total = 0;
        this.faturas.map((item) => {
          if (item.statusFatura === "Em aberto") {
            total += 1;
          }
        });
        return total;
      },
    },
    totalFaturas: {
      get() {
        var total = 0;
        this.faturas.map((item) => {
          if (item.statusFatura === "Em aberto") {
            // console.log(item.total.toFixed(2))
            // total += parseFloat(item.total);
            total += Math.round(item.total * 100) / 100;
          }
        });
        return total.toFixed(2);
      },
    },
  },
};
</script>

<style>
</style>