var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Adicionar Fatura ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.allClientes,"item-text":"name","label":"Nome do Cliente","return-object":""},model:{value:(_vm.cliente),callback:function ($$v) {_vm.cliente=$$v},expression:"cliente"}}),_c('v-text-field',{attrs:{"name":"produto_servico","label":"Produto/Serviço"},model:{value:(_vm.produto),callback:function ($$v) {_vm.produto=$$v},expression:"produto"}}),_c('v-text-field',{attrs:{"name":"numero_fatura","type":"number","label":"Numero da Fatura"},model:{value:(_vm.faturaNumero),callback:function ($$v) {_vm.faturaNumero=$$v},expression:"faturaNumero"}}),_c('v-dialog',{ref:"dialog1",attrs:{"return-value":_vm.dataMesRef,"width":"290px"},on:{"update:returnValue":function($event){_vm.dataMesRef=$event},"update:return-value":function($event){_vm.dataMesRef=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Mês de Referencia","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dataMesRef),callback:function ($$v) {_vm.dataMesRef=$$v},expression:"dataMesRef"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.mesRef),callback:function ($$v) {_vm.mesRef=$$v},expression:"mesRef"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","min":new Date().toISOString().substr(0, 10)},on:{"input":function($event){_vm.mesRef = false;}},model:{value:(_vm.dataMesRefPick),callback:function ($$v) {_vm.dataMesRefPick=$$v},expression:"dataMesRefPick"}})],1),_c('v-dialog',{ref:"dialog2",attrs:{"return-value":_vm.dataEnvio,"width":"290px"},on:{"update:returnValue":function($event){_vm.dataEnvio=$event},"update:return-value":function($event){_vm.dataEnvio=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de Envio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dataEnvio),callback:function ($$v) {_vm.dataEnvio=$$v},expression:"dataEnvio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataEnv),callback:function ($$v) {_vm.dataEnv=$$v},expression:"dataEnv"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","min":new Date().toISOString().substr(0, 10)},on:{"input":function($event){_vm.dataEnv = false}},model:{value:(_vm.dataEnvioPick),callback:function ($$v) {_vm.dataEnvioPick=$$v},expression:"dataEnvioPick"}})],1),_c('v-dialog',{ref:"dialog3",attrs:{"return-value":_vm.dataVencimento,"width":"290px"},on:{"update:returnValue":function($event){_vm.dataVencimento=$event},"update:return-value":function($event){_vm.dataVencimento=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data de Vencimento","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dataVencimento),callback:function ($$v) {_vm.dataVencimento=$$v},expression:"dataVencimento"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dataVenc),callback:function ($$v) {_vm.dataVenc=$$v},expression:"dataVenc"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","min":new Date().toISOString().substr(0, 10)},on:{"input":function($event){_vm.dataVenc = false}},model:{value:(_vm.dataVencimentoPick),callback:function ($$v) {_vm.dataVencimentoPick=$$v},expression:"dataVencimentoPick"}})],1),_c('v-text-field',{attrs:{"name":"total","type":"number","label":"Total"},model:{value:(_vm.total),callback:function ($$v) {_vm.total=$$v},expression:"total"}}),_c('v-file-input',{attrs:{"accept":".pdf","label":"Fatura"},model:{value:(_vm.fatura),callback:function ($$v) {_vm.fatura=$$v},expression:"fatura"}}),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"text-none",attrs:{"block":"","color":"#C3F207"},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Cancelar ")])],1),_c('v-col',[_c('v-btn',{staticClass:"text-none",attrs:{"block":"","color":"#C3F207"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Criar ")])],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }