import { Auth } from "aws-amplify";

export const auth = {

    namespaced: true,
    state: { user: null, UserGroup: null },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setUserGroup(state, payload) {
            state.UserGroup = payload
        }
    },
    actions: {
        async logout({ commit }) {
            commit("setUser", null);

            return await Auth.signOut();
        },
        async login({ commit }, { username, password }) {
            try {
                await Auth.signIn({
                    username,
                    password
                })
                // .then((user) => {
                //     console.log(user)
                // })
                const userInfo = await Auth.currentUserInfo();
                if(userInfo.attributes["custom:challengeName"] === "NEW_PASSWORD_REQUIRED"){
                    return "NEW_PASSWORD_REQUIRED"
                }
                commit("setUser", userInfo);
                // console.log(userInfo)
                const isSession = await Auth.currentSession();
                commit("setUserGroup", isSession.accessToken.payload["cognito:groups"])
                return Promise.resolve("Success");


            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async confirmSignUp(_, { username, code }) {
            try {
                await Auth.confirmSignUp(username, code);
                return Promise.resolve();

            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async signUp(_, { username, email, name, password }) {
            var userID = ""
            try {
                await Auth.signUp({
                    username,
                    password,
                    attributes: {
                        email,
                        name,
                        'custom:challengeName': "NEW_PASSWORD_REQUIRED"
                    }
                })
                    .then(data => {
                        userID = data.userSub
                    })
                return (userID)
                // return Promise.resolve("Sucesso");

            } catch (error) {
                console.log(error);
                return Promise.reject(error);

            }
        },
        async authAction({ commit }) {
            const userInfo = await Auth.currentUserInfo();
            commit("setUser", userInfo);

            const isSession = await Auth.currentSession();
            commit("setUserGroup", isSession.accessToken.payload["cognito:groups"])

        },
        async updateAttribute(_, { email }) {
            try {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.updateUserAttributes(user, { email: email });
                return Promise.resolve();
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },

        async confirmAttribute(_, { code }) {
            try {
                await Auth.verifyCurrentUserAttributeSubmit("email", code);
                return Promise.resolve();

            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },

        async forgotPass(_, { email }) {
            try {
                await Auth.forgotPassword(email);
                return Promise.resolve();

            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },

        async ConfirmforgotPass(_, { email, code, new_password }) {
            try {
                await Auth.forgotPasswordSubmit(email, code, new_password);
                return Promise.resolve();

            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },

        async changePassword(_, { oldPassword, newPassword }) {
            try {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.changePassword(user, oldPassword, newPassword);
                return Promise.resolve();
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        },

        async completeNewPassword(_, { oldPassword, newPassword }) {
            try {
                const user = await Auth.currentAuthenticatedUser();
                await Auth.changePassword(user, oldPassword, newPassword);
                await Auth.updateUserAttributes( user, { 'custom:challengeName': "COMPLETE" });
                return Promise.resolve();
            } catch (error) {
                console.log(error);
                return Promise.reject(error);
            }
        }

    },
    getters: {
        user: (state) => state.user

    },

}